import React, { useState } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavRoad from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"

import {
  Title,
  SubTitle,
  Button,
  Span,
  Textarea,
} from "../../../StyleComponents/styles"

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  {
    name: "JSON to CSV",
    link: "/json-to-csv-conversion/",
  },
]
const seeAlsoArray = [
  "/csv-to-json-converter",
  "/csv-to-json-converter",
  "/csv-to-tabel",
  "/csv-to-sql-conversion",
  "/json-to-xml",
  "/text-to-csv-conversion",
]

function JSONtoCSV(props) {
  let [jsonTextarea, setjsonTextarea] = useState(
    '[\n {\n  "Id":1,"UserName":"Roy"  \n },\n {\n  "Id":2,"UserName":"Mike"\n  },\n {\n  "Id":3,"UserName":"Jamal" \n } \n]'
  )
  let [csvTexarea, setcsvTexarea] = useState("")

  function jsonTextareaC(e) {
    setjsonTextarea(e.target.value)
  }

  function convert() {
    let data = jsonTextarea
    import("csvjson").then(e => {
      let csvjson = e.default
      let csv = csvjson.toCSV(data)
      setcsvTexarea(csv)
    })

    // csvjson.toCSV(data);
  }

  function handleFileSelect(evt) {
    if (window.File && window.FileReader && window.FileList && window.Blob) {
      var f = evt.target.files[0]
      var reader = new FileReader()
      reader.onload = (function () {
        return function (e) {
          var jsonInput = e.target.result
          setjsonTextarea(jsonInput)
        }
      })(f)
      reader.readAsBinaryString(f)
    }
  }

  function downloadObjectAsJson() {
    var dataStr =
      "data:text/json;charset=utf-8," + encodeURIComponent(csvTexarea)
    var downloadAnchorNode = document.createElement("a")
    downloadAnchorNode.setAttribute("href", dataStr)
    downloadAnchorNode.setAttribute("download", "csv-file.csv")
    document.body.appendChild(downloadAnchorNode) // required for firefox
    downloadAnchorNode.click()
    downloadAnchorNode.remove()
  }
  return (
    <Layout location={props.location}>
      <SEO
        title="Json To Csv, JSON To CSV Conversion"
        description="JSON To CSV, Json To Csv Conversion, Simple Converter, JSON to CSV will convert an array of objects into a table, step by step."
        keywords={[
          "Json To Csv Converter,Json To Csv translator,Json To Csv, Json To Csv translator,convert Json To Csv, Json To Csv convertion, Json To Csv, JSON To CSV, Json To Csv converter and download, array of objects to csv, json csv conversion.",
        ]}
      />
      <NavRoad listPages={navRoadArray} />
      <div className="container">
        <Title>Convert JSON to CSV</Title>

        <SubTitle>JSON to CSV</SubTitle>
        <label>
          <input
            type="file"
            accept=".txt,.json"
            aria-label="files"
            onChange={handleFileSelect}
          />
          <span>Choose a file… </span>
        </label>
        <br />
        <Span>Or Paste Your JSON Here:</Span>

        <Textarea value={jsonTextarea} onChange={jsonTextareaC}></Textarea>
        <Button
          borderColor="#1da1f2"
          color="#1da1f2"
          hoverColor="white"
          hoverBorderColor="#1da1f2"
          hoverBackgroundColor="#1da1f2"
          className="mb-2"
          onClick={convert}
        >
          Convert To CSV
        </Button>
        <Textarea value={csvTexarea} readOnly></Textarea>
        <Button
          borderColor="#1da1f2"
          color="white"
          backgroundColor="#1da1f2"
          hoverColor="#1da1f2"
          hoverBorderColor="#1da1f2"
          className="mb-2"
          onClick={downloadObjectAsJson}
        >
          Download CSV
        </Button>
        <br />
        <h3>Json To Csv</h3>
        <p>
          Input or upload your JSON and click on the button converter to make
          the conversion of JSON format to CSV format.
          <br />
        </p>
        <br />
        <h3>CSV</h3>
        <p>
          CSV file is a plain text of a data list, it is used for the database.
          A Comma separated values CSV file.
          <br />
          CSV file also called Character Separated Values or Comma Delimited
          files. They mostly use the comma character to separate data.
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default JSONtoCSV
